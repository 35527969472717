<template>
  <!-- adapted from https://codepen.io/john-sexton/pen/oeGGag -->
  <div>
    <nav class="mobile-bottom-nav is-hidden-desktop">
      <div class="mobile-bottom-nav__item mobile-bottom-nav__item--active">
        <div class="mobile-bottom-nav__item-content">
          <router-link to="/" :class="{ 'selected': homeSelected }">
            <font-awesome-icon style="margin-right: 0.3rem" icon="home"></font-awesome-icon>
            <p>Home</p>
          </router-link>
        </div>
      </div>
      <div class="mobile-bottom-nav__item">
        <div class="mobile-bottom-nav__item-content">
          <router-link to="/search" :class="{ 'selected': searchSelected }">
            <font-awesome-icon style="margin-right: 0.3rem" icon="search"></font-awesome-icon>
            <p>Search</p>
          </router-link>
        </div>
      </div>
      <div class="mobile-bottom-nav__item">
        <div class="mobile-bottom-nav__item-content">
          <router-link to="/discover" :class="{ 'selected': discoverSelected }">
            <font-awesome-icon style="margin-right: 0.3rem" icon="binoculars"></font-awesome-icon>
            <p>Discover</p>
          </router-link>
        </div>
      </div>

      <div class="mobile-bottom-nav__item">
        <div class="mobile-bottom-nav__item-content">
          <router-link to="/watchlist" :class="{ 'selected': watchlistSelected }">
            <font-awesome-icon style="margin-right: 0.3rem" icon="stream"></font-awesome-icon>
            <p>Watchlist</p>
          </router-link>
        </div>
      </div>
      <div class="mobile-bottom-nav__item">
        <div class="mobile-bottom-nav__item-content">
          <router-link to="/favorites" :class="{ 'selected': favoritesSelected }">
            <font-awesome-icon style="margin-right: 0.3rem" icon="heart"></font-awesome-icon>
            <p>Favorites</p>
          </router-link>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import { Bus } from "@/utils/Bus.js";
import TMDb from "@/utils/TMDb.js";

const tmdb = new TMDb();

export default {
  name: "Navbar",
  props: ["item"],
  data() {
    return {
      isActive: false,
      signinmsg: "Connect to TMDb",
      homeSelected: false,
      searchSelected: false,
      discoverSelected: false,
      watchlistSelected: false,
      favoritesSelected: false
    };
  },
  created() {
    this.handleSelects(this.item);
    this.handleSignins();
    Bus.$on("signin", this.handleSignins);
  },
  watch: {
    item() {
      this.handleSelects(this.item);
    }
  },
  methods: {
    handleSelects(button) {
      this.homeSelected = false;
      this.searchSelected = false;
      this.discoverSelected = false;
      this.watchlistSelected = false;
      this.favoritesSelected = false;
      switch (button) {
        case "home":
          this.homeSelected = true;
          break;
        case "search":
          this.searchSelected = true;
          break;
        case "discover":
          this.discoverSelected = true;
          break;
        case "watchlist":
          this.watchlistSelected = true;
          break;
        case "favorites":
          this.favoritesSelected = true;
          break;
      }
    },
    handleSignins() {
      if (tmdb.getAccountId() && tmdb.getAccessToken()) {
        this.signinmsg = "Sign out";
      } else {
        this.signinmsg = "Connect to TMDb";
      }
    },
    handleConnectButtonClick() {
      if (tmdb.getAccountId() && tmdb.getAccessToken()) {
        tmdb.logoff();
      } else {
        tmdb.setup();
      }
    }
  }
};
</script>

<style scoped>
.signin img {
  padding-left: 10px;
  padding-right: 10px;
}
.signin strong {
  padding-right: 10px;
}

/* .custom-navbar {
  background: black;
  position: fixed;
  bottom: 1rem;
  display: inline-flex;
  height: 2rem;
  width: 100%;
} */

.mobile-bottom-nav {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  height: 50px;
  box-shadow: 0 -2px 5px -2px #333;
  background-color: white;
}

.mobile-bottom-nav__item {
  -webkit-box-flex: 1;
  flex-grow: 1;
  text-align: center;
  font-size: 12px;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
}
.selected {
  color: #09c0f4 !important;
}
.mobile-bottom-nav__item-content {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}

@media (prefers-color-scheme: dark) {
  .mobile-bottom-nav {
    background-color: black !important;
  }
}
</style>