<template>
  <footer class="phlix-footer">
    <div class="content" style="padding-left: 0rem">
      <div class="level">
        <div class="level-left">
          <div class="level is-mobile">
            <div class="level-left">
              <img
                style="height: 2.5rem; display: inline-block; vertical-align: middle"
                src="@/assets/images/phlix-large.svg"
              />
            </div>
            <div class="level-right">
              <p
                style="font-size: 80%; font-weight: 500; display: inline-block; vertical-align: middle; margin-left: 1rem;"
              >Created by Kevin Bryniak</p>
            </div>
          </div>
        </div>
        <div class="level-right">
          <div class="level is-mobile">
            <div class="level-left">
              <p
                style="font-size: 60%; display: inline-block; vertical-align: middle; margin-right: 1rem"
              >
                This product uses the TMDb API
                <br />but is not endorsed or certified by TMDb.
              </p>
            </div>
            <div class="level-right">
              <img
                style="height: 2rem; display: inline-block; vertical-align: middle"
                src="@/assets/images/tmdb-logos/powered-by-rectangle-green.svg"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="is-hidden-desktop" style="margin-bottom: 2rem"></div>
  </footer>
</template>

<script>
export default {};
</script>

<style scoped>
.phlix-footer {
  padding: 0;
  margin-bottom: 0.3rem;
  margin-top: 3rem;
  width: 100%;
  background-color: transparent;
}
</style>