<template>
  <nav class="navbar" style="margin-bottom: 2rem;" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
      <a class="navbar-item">
        <router-link to="/">
          <img src="../assets/images/phlix-large.svg" style="max-height: 2.75rem !important" />
        </router-link>
      </a>

      <a
        role="button"
        class="navbar-burger burger"
        :class="{ 'is-active' : isActive}"
        aria-label="menu"
        aria-expanded="false"
        data-target="navbarBasicExample"
        @click="handleBurgerClicks($event)"
      >
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>

    <div
      id="navbarBasicExample"
      @click="handleBurgerClicks($event)"
      :class="{ 'is-active' : isActive }"
      class="navbar-menu"
    >
      <div class="navbar-start">
        <a class="navbar-item">
          <router-link to="/">
            <font-awesome-icon style="margin-right: 0.3rem" icon="home"></font-awesome-icon>Home
          </router-link>
        </a>
        <a class="navbar-item">
          <router-link to="/search">
            <font-awesome-icon style="margin-right: 0.3rem" icon="search"></font-awesome-icon>Search
          </router-link>
        </a>
        <a class="navbar-item">
          <router-link to="/discover">
            <font-awesome-icon style="margin-right: 0.3rem" icon="binoculars"></font-awesome-icon>Discover
          </router-link>
        </a>
        <a class="navbar-item">
          <router-link to="/recommendations">
            <font-awesome-icon style="margin-right: 0.3rem" icon="lightbulb"></font-awesome-icon>Recommendations
          </router-link>
        </a>
        <a class="navbar-item">
          <router-link to="/watchlist">
            <font-awesome-icon style="margin-right: 0.3rem" icon="stream"></font-awesome-icon>Watchlist
          </router-link>
        </a>
        <a class="navbar-item">
          <router-link to="/favorites">
            <font-awesome-icon style="margin-right: 0.3rem" icon="heart"></font-awesome-icon>Favorites
          </router-link>
        </a>
        <a class="navbar-item">
          <router-link to="/about">
            <font-awesome-icon style="margin-right: 0.3rem" icon="question"></font-awesome-icon>About
          </router-link>
        </a>
      </div>

      <div class="navbar-end">
        <div class="navbar-item">
          <div class="buttons">
            <a class="button is-link is-rounded signin" @click="handleConnectButtonClick()">
              <img style="height: 80%" :src="tmdblogo" />
              <strong>{{ signinmsg }}</strong>
            </a>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { Bus } from "@/utils/Bus.js";
import TMDb from "@/utils/TMDb.js";

const tmdb = new TMDb();

export default {
  name: "Navbar",
  data() {
    return {
      isActive: false,
      signinmsg: "Connect to TMDb",
      tmdblogo: require('@/assets/images/tmdb-logos/tmdb-stacked-green.svg')
    };
  },
  created() {
    this.handleSignins();
    Bus.$on("signin", this.handleSignins);
  },
  methods: {
    handleBurgerClicks(event) {
      let tag = event.target.tagName;
      if (tag == "A" || tag == "SPAN" || tag == "IMG" || tag == "STRONG") {
        this.isActive ? (this.isActive = false) : (this.isActive = true);
      }
    },
    handleSignins() {
      if (tmdb.getAccountId() && tmdb.getAccessToken()) {
        this.signinmsg = "Sign out";
      } else {
        this.signinmsg = "Connect to TMDb";
      }
    },
    handleConnectButtonClick() {
      if (tmdb.getAccountId() && tmdb.getAccessToken()) {
        tmdb.logoff();
      } else {
        tmdb.setup();
      }
    }
  }
};
</script>

<style scoped>
.signin img {
  padding-left: 10px;
  padding-right: 10px;
}
.signin strong {
  padding-right: 10px;
}
</style>