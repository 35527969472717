<template>
  <div :style="plotMargin" v-if="plot !== ''" class="column is-12-mobile is-10-desktop">
    <p class="movie-plot">
      <span
        style="font-variant: small-caps; font-weight: 600; color: #4d4d4d;"
      >{{ title != undefined ? title : "plot"}}</span>
      <br />
      {{Boolean(shorten) == true ? shortened(expanded) : plot}}
      <a
        @click="handleClicks()"
        v-if="Boolean(shorten) == true && plot.length > length"
      >{{ expandmsg }}</a>
    </p>
  </div>
</template>

<script>
export default {
  props: ["plot", "title", "shorten", "length", "margin"],
  data() {
    return {
      expanded: false,
      expandmsg: "more",
    };
  },
  watch: {
    plot() {
      this.expanded = false;
      this.expandmsg = "more";
    },
  },
  computed: {
    plotMargin() {
      if (this.margin == undefined) {
        return "margin: auto;";
      } else {
        return this.margin;
      }
    },
  },
  methods: {
    shortened(expanded) {
      let plot = this.plot;
      return expanded
        ? plot
        : plot.length > this.length
        ? plot.slice(0, this.length) + "..."
        : plot;
    },
    handleClicks() {
      if (this.expanded == true) {
        this.expanded = false;
        this.expandmsg = "more";
      } else {
        this.expanded = true;
        this.expandmsg = "less";
      }
    },
  },
};
</script>

<style scoped>
.movie-plot {
  border: 2px solid #4d4d4d;
  border-right: none;
  border-bottom: none;
  border-top: none;
  padding-left: 0.7em;
}
</style>