<template>
  <li>
    <div class="level is-mobile">
      <div class="level-left">
        <a :href="personurl">
          <span class="heading" style="display: inline-block;">{{ name }}</span>
        </a>
      </div>
      <div class="level-right" style="flex-shrink: unset !important;">
        <a :href="moduleurl" style="display: flex; align-items: end; margin-left: 0.55rem">
          <span
            style="font-family: courier new; text-align: right; white-space: normal"
          >{{ modulename }}</span>
        </a>
      </div>
    </div>
  </li>
</template>

<script>
export default {
  name: "modulecard",
  props: ["name", "personurl", "modulename", "moduleurl"]
};
</script>