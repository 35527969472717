<template>
  <div>
    <phlix-navbar></phlix-navbar>

    <div class="container is-fluid">
      <h1 class="title is-1">About</h1>

      <div class="columns is-multiline">
        <div class="column is-6">
          <plot
            title="backstory"
            :shorten="true"
            :length="200"
            plot="Phlix was created as an experiment, because there were no good apps offering the ability to search, discover, and filter movies and TV shows based on your preferences. There are other apps offering the ability to search and discover movies and TV shows, but most have limited options and features. Nevertheless, what really makes Phlix different from others is its clean and modern UI. Phlix was designed to look and feel like a mobile app when being used on a mobile device, with bottom navigation, side-scrolling, and a dark theme. It has many features, including the ability to search for movies, TV shows, and people, discover new films and shows, based on your preferences, and proving recommendations based on what movies and TV shows you like. Detailed information about movies, TV shows, and people let you see even the most trivial facts about them, like, for instance, the age rating of the Swedish version of the physical copy of a movie made in 1995 featuring Will Smith. Moreover, Phlix lets you connect to TMDb and access your watchlist, favorites, recommendations—and be able to rate both movies and TV shows. Find your new favorite with Phlix."
          ></plot>
        </div>
        <div class="column is-11">
          <p class="title is-4">Support</p>
          <p
            class="subtitle is-6"
          >While Phlix always will be both ad-free and completly free to use (as long as the services it relies on remain the same), if you like it and want to show your appriciation, please consider making a donation.</p>
        </div>
        <div class="column is-6" style="display: flex; justify-content: center">
          <a
            href="https://paypal.me/thephlixapp/"
            class="button is-link is-rounded"
            name="submit"
            alt="Support Phlix"
          >
            <font-awesome-icon icon="hand-holding-usd"></font-awesome-icon>
            <span style="margin-left: 0.35rem">Support Phlix</span>
          </a>
        </div>
        <div class="column is-11">
          <p class="subtitle is-6">
            However, if you have any questions or problems with or about Phlix, send an email to
            <a
              href="mailto:thephlixapp@gmail.com"
            >thephlixapp@gmail.com</a>. Submit bugs and feature requests on the
            <a
              href="https://trello.com/invite/b/IutLgmLl/b56112e49f7d607ac77b98e0a3d80621/phlix"
            >Phlix trello board.</a>
          </p>
        </div>
        <div class="column is-11">
          <p class="title is-4">Credits</p>
          <p
            class="subtitle is-6"
          >Phlix wouldn't be possible without these amazing modules and services.</p>
        </div>
        <div class="column is-6">
          <div class="tile is-ancestor columns is-multiline is-centered">
            <logocard
              class="hidden-on-light-mode"
              :imageurl="require('@/assets/images/bulma-light.png')"
              clickurl="https://bulma.io"
              alttext="Made with Bulma"
              size="100%"
            ></logocard>
            <logocard
              class="hidden-on-dark-mode"
              :imageurl="require('@/assets/images/bulma-dark.png')"
              clickurl="https://bulma.io"
              alttext="Made with Bulma"
              size="100%"
            ></logocard>
            <logocard
              :imageurl="require('@/assets/images/vue.png')"
              clickurl="https://vuejs.org"
              alttext="Made with Vue"
              size="85%"
            ></logocard>
            <logocard
              :imageurl="require('@/assets/images/tmdb.svg')"
              clickurl="https://themoviedb.org"
              alttext="Powered by TMDb"
              size="100%"
            ></logocard>
            <logocard
              :imageurl="require('@/assets/images/font-awesome.png')"
              clickurl="https://fontawesome.com"
              alttext="Made with Font Awesome"
              size="100%"
              :border="true"
            ></logocard>
            <logocard
              :imageurl="require('@/assets/images/lodash.png')"
              clickurl="https://lodash.com"
              alttext="Made with Font Awesome"
              size="80%"
            ></logocard>
            <logocard
              :imageurl="require('@/assets/images/moment.png')"
              clickurl="https://momentjs.com"
              alttext="Made with Moment.js"
              size="100%"
              :border="true"
            ></logocard>
          </div>
        </div>
        <div class="column is-6 is-paddingless">
          <div class="tile is-parent">
            <article class="tile is-child box">
              <p class="title is-5">Others</p>
              <ul>
                <modulecard
                  name="James Loh"
                  personurl="https://github.com/jloh"
                  modulename="bulma-prefers-dark"
                  moduleurl="https://github.com/jloh/bulma-prefers-dark"
                ></modulecard>
                <modulecard
                  name="Axios"
                  personurl="https://github.com/axios"
                  modulename="axios"
                  moduleurl="https://github.com/axios/axios"
                ></modulecard>
                <modulecard
                  name="James Barnett"
                  personurl="https://codepen.io/jamesbarnett"
                  modulename="Pure CSS Star Rating Widget"
                  moduleurl="https://codepen.io/jamesbarnett/pen/vlpkh"
                ></modulecard>
                <modulecard
                  name="Wikiki"
                  personurl="https://github.com/Wikiki"
                  modulename="bulma-timeline"
                  moduleurl="https://github.com/Wikiki/bulma-timeline"
                ></modulecard>
                <modulecard
                  name="Fannar Snær Harðarson"
                  personurl="https://github.com/fannarsh"
                  modulename="country-list"
                  moduleurl="https://github.com/fannarsh/country-list"
                ></modulecard>
                <modulecard
                  name="meikidd"
                  personurl="https://github.com/meikidd"
                  modulename="iso-639-1"
                  moduleurl="https://github.com/meikidd/iso-639-1"
                ></modulecard>
                <modulecard
                  name="Léon Gersen"
                  personurl="https://github.com/leongersen"
                  modulename="noUiSlider"
                  moduleurl="https://github.com/leongersen/noUiSlider"
                ></modulecard>
                <modulecard
                  name="Dustan Kasten"
                  personurl="https://github.com/iamdustan"
                  modulename="smoothscroll"
                  moduleurl="https://github.com/iamdustan/smoothscroll"
                ></modulecard>
                <modulecard
                  name="SSENSE"
                  personurl="https://github.com/SSENSE"
                  modulename="vue-carousel"
                  moduleurl="https://github.com/SSENSE/vue-carousel"
                ></modulecard>
                <modulecard
                  name="PeachScript"
                  personurl="https://github.com/PeachScript"
                  modulename="vue-infinite-loading"
                  moduleurl="https://github.com/PeachScript/vue-infinite-loading"
                ></modulecard>
                <modulecard
                  name="Freepik"
                  personurl="https://www.flaticon.com/authors/freepik"
                  modulename="Countrys Flags Icon Pack"
                  moduleurl="https://www.flaticon.com/packs/countrys-flags"
                ></modulecard>
                <modulecard
                  name="John Sexton"
                  personurl="https://codepen.io/john-sexton"
                  modulename="Mobile Fixed Bottom Nav"
                  moduleurl="https://codepen.io/john-sexton/pen/oeGGag"
                ></modulecard>
                <router-link
                  to="licenses"
                  class="subtitle is-6 is-link button is-rounded"
                  style="margin-top: 1rem"
                >
                  <font-awesome-icon icon="copyright"></font-awesome-icon>
                  <p style="margin-left: 0.5rem">View all open source licenses</p>
                </router-link>
                <!-- <modulecard
                name=""
                personurl=""
                modulename=""
                moduleurl=""
                ></modulecard>-->
              </ul>
            </article>
          </div>
        </div>
      </div>
      <phlix-footer style="margin-bottom: 5rem"></phlix-footer>
    </div>
    <bottomnavbar></bottomnavbar>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Bottomnavbar from "@/components/Bottomnavbar";
import Logocard from "@/components/Logocard.vue";
import Modulecard from "@/components/Modulecard.vue";
import Plot from "@/components/movies/Plot.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "about",
  components: {
    "phlix-navbar": Navbar,
    Bottomnavbar,
    Logocard,
    Modulecard,
    Plot,
    "phlix-footer": Footer,
  },
};
</script>

<style scoped>
@media (prefers-color-scheme: dark) {
  .hidden-on-dark-mode {
    display: none;
  }
}

@media (prefers-color-scheme: light) {
  .hidden-on-light-mode {
    display: none;
  }
}
</style>



