<template>
  <div class="tile is-parent column is-6">
    <article class="tile is-child box" style="display: flex; justify-content: center">
      <a style="margin: auto" :href="clickurl">
        <img class="center" :style="imagesize" :src="imageurl" :alt="alttext" />
      </a>
    </article>
  </div>
</template>

<script>
export default {
  name: "logocard",
  props: ["imageurl", "clickurl", "alttext", "size", "border"],
  computed: {
    imagesize() {
      let style =
        this.size != undefined || this.size != null || this.size != ""
          ? "width: " + this.size + "; "
          : "";
      this.border ? (style += "border-radius: 5px") : {};
      return style;
    }
  }
};
</script>

<style scoped>
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
</style>